import type { Nodes } from "hast";
import { type Options, toJsxRuntime } from "hast-util-to-jsx-runtime";
import { toString as convertToString } from "hast-util-to-string";
import { type JSX, Show, splitProps } from "solid-js";
import { isServer } from "solid-js/web";
import { Fragment, jsx, jsxs } from "solid-jsx/jsx-runtime";

import navStyles from "~/components/PostNav.module.scss";
import SmartA from "~/components/SmartA";
import { clientLazy } from "~/utils/clientLazy";

const CopyButton = clientLazy(() => import("~/components/CopyButton"));
const LyricsTable = clientLazy(() => import("~/components/LyricsTable"));
const PlayerForm = clientLazy(() => import("~/components/PlayerForm"));
const PostNav = clientLazy(() => import("~/components/PostNav"));

const jsxOptions: Options = {
  components: {
    nav(props) {
      return (
        <Show
          when={!isServer && props.class === navStyles.toc}
          fallback={<nav {...props} />}
        >
          <PostNav {...props} />
        </Show>
      );
    },
    div(props) {
      return (
        <Show when={props["data-fake-paragraph"]} fallback={<div {...props} />}>
          {props.children}
        </Show>
      );
    },
    section(props) {
      return (
        <Show
          when={!isServer && props["data-component"] === "PlayerForm"}
          fallback={<section {...props} />}
        >
          <PlayerForm />
        </Show>
      );
    },
    a(props) {
      return (
        <Show when={!isServer} fallback={<a {...props} />}>
          <SmartA {...props} />
        </Show>
      );
    },
    pre(props) {
      const [children, rest] = splitProps(props, ["children"]);
      return (
        <pre {...rest}>
          {children.children}
          <CopyButton />
        </pre>
      );
    },
    table(props) {
      return (
        <Show
          when={
            !isServer &&
            (props.class?.includes("lyrics") || props.class?.includes("letra"))
          }
          fallback={<table {...props} />}
        >
          <LyricsTable {...props} />
        </Show>
      );
    },
  },
  Fragment,
  jsx,
  jsxs,
  elementAttributeNameCase: "html",
  stylePropertyNameCase: "css",
};

export function fragmentToJsx(
  fragment?: Nodes,
  plainText = false,
): JSX.Element {
  if (!fragment) {
    return <></>;
  }

  if (plainText) {
    return convertToString(fragment);
  }

  return toJsxRuntime(fragment, jsxOptions);
}
